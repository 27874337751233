import { render, staticRenderFns } from "./StudentHelpDetail.vue?vue&type=template&id=17004a25&scoped=true"
import script from "./StudentHelpDetail.vue?vue&type=script&lang=js"
export * from "./StudentHelpDetail.vue?vue&type=script&lang=js"
import style0 from "./StudentHelpDetail.vue?vue&type=style&index=0&id=17004a25&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17004a25",
  null
  
)

export default component.exports